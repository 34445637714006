.contentManagmentWrapper {
  background: #f5f5f5;
  width: 100%;
  padding: 3%;
  height: calc(100vh - 82px);
}

.contentDivBackground {
  background: white;
  padding: 1%;
  border-radius: 8px;
}

.cmAddBtn {
  width: 80%;
  padding: 4%;
  color: white;
  background: #C51453;
  border-radius: 8px;
}

.contentManagmentFilterWrapper {
  margin-top: 1%;
  width: 100%;
}

.cmfwHead {
  font-weight: bold;
  padding: 1%;
}

.cmfwHeadSpan {
  background: linear-gradient(120deg, #c51453 0%, #ffb238 100%);
  color: white;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 2%;
  padding-right: 2%;
  border-radius: 8px;
  margin-left: 4%;
  font-weight: normal;
}

.cmfwHead2 {
  padding: 1%;
}

.cmfwHead2Select {
  margin-left: 4%;
  width: 100%;
  transform: translate(0, -20%);
}

.cmfwHead3 {
  padding: 1%;
}

.cmfwCheckIcon {
  background: linear-gradient(120deg, #c51453 0%, #ffb238 100%);
  color: white;
  padding: 1.2%;
  border-radius: 100px;
  transform: translate(0, 10%);
}

.cmfwHead3Span {
  margin-left: 4%;
}

.cmContentWrapper {
  width: 100%;
}

.cmcwCartItem {
  margin-top: 5%;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.25);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.13),
    inset 0px 1px 8px rgba(255, 255, 255, 0.15);
}

.cmciIcon1W {
  /*background: red;*/
  width: 100%;
  text-align: left;
  font-size: 11px;
}

.cmciIcon2W {
  /*background: yellow;*/
  width: 100%;
  text-align: left;
  font-size: 11px;
}

.cmciIcon3W {
  /*background: red;*/
  width: 100%;
  text-align: right;
  font-size: 11px;
}

.cmciIcon1W i {
  font-weight: bold;
}

.cmciIcon2W i {
  font-weight: bold;
}

.cmciIcon3W i {
  font-weight: bold;
}

.cardIcons {
  color: #c51453;
}
.cmCardImg{
  height: 200px;
  object-fit: cover;
}

@media screen and (max-width: 1024px) {
  .cmciIcon1W {
    font-size: 15px;
  }

  .cmciIcon2W {
    font-size: 15px;
  }

  .cmciIcon3W {
    font-size: 15px;
  }

  .cmMainHead {
    margin-top: 5%;
  }
}

@media screen and (max-width: 700px) {
  .cmAddBtn {
    font-size: 14px;
  }
}

@media screen and (max-width: 575px) {
  .cmAddBtn {
    width: 100%;
  }
}
