.missionWrapper {
    width: 100%;
    padding: 3%;
}

.mtParent {
    width: 100%;
    display: flex;
}

.mtParent2 {
    width: 100%;
    display: flex;
    margin-top: 5%;
}

.mtChild11 {
    width: 90%;
    /*background: lightcoral;*/
    padding: 0.5%;
    color: #c51453;
    border-bottom: 4px solid #c51453;
    border-right: 6px solid #c51453;
}

.mtChild12 {
    width: 10%;
    /*background: lightblue;*/
    text-align: right;
    padding: 0.5%;
    color: #c51453;
    border-top: 4px solid #c51453;
}

.mCParent {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.mCChild {
    width: 100%;
    height: 230px;
    border: 5px solid #c51453;
    margin-top: 1%;
    margin-right: 1.6%;
    border-radius: 10px;
    overflow: hidden;
}

.mCImg {
    width: 100%;
    object-fit: cover;
    height: 230px;
    object-fit: cover;
}

.mCImgLocked {
    width: 100%;
    height: 220px;
    opacity: 0.2;
    object-fit: cover;
}

.mCChildLocked {
    width: 100%;
    height: 230px;
    border: 5px solid gray;
    margin-top: 1%;
    margin-right: 1.6%;
    border-radius: 10px;
}



@media screen and (max-width: 1366px) {}


@media screen and (max-width: 1280px) {}


@media screen and (max-width: 1024px) {
    .mCChild {
        height: 180px;
    }

    .mCChildLocked {
        height: 180px;
    }

    .mCImg {
        height: 180px;
    }

    .mCImgLocked {
        height: 170px;
    }
}

@media screen and (max-width: 900px) {
    .mtChild11 {
        width: 85%;

    }

    .mtChild12 {
        width: 15%;
    }
    .mCChild {
        height: 220px;
    }

    .mCChildLocked {
        height: 220px;
    }

    .mCImg {
        height: 220px;
    }

    .mCImgLocked {
        height: 210px;
    }
}

@media screen and (max-width: 800px) {
    .mCChild {
        height: 200px;
    }

    .mCChildLocked {
        height: 200px;
    }

    .mCImg {
        height: 200px;
    }

    .mCImgLocked {
        height: 190px;
    }
}


@media screen and (max-width: 700px) {
    .mCChild {
        height: 170px;
    }

    .mCChildLocked {
        height: 170px;
    }

    .mCImg {
        height: 170px;
    }

    .mCImgLocked {
        height: 160px;
    }

    .mtChild11 {
        width: 83%;

    }

    .mtChild12 {
        width: 17%;
    }
}

@media screen and (max-width: 600px) {
    .mCChild {
        height: 150px;
    }

    .mCChildLocked {
        height: 150px;
    }

    .mCImg {
        height: 150px;
    }

    .mCImgLocked {
        height: 140px;
    }

    .mtChild11 {
        width: 83%;

    }

    .mtChild12 {
        width: 17%;
    }
}

@media screen and (max-width: 575px) {
    .mtChild11 {
        width: 75%;

    }

    .mtChild12 {
        width: 25%;
    }

    .mCChild {
        height: 140px;
    }

    .mCChildLocked {
        height: 140px;
    }

    .mCImg {
        height: 140px;
    }

    .mCImgLocked {
        height: 130px;
    }
}

@media screen and (max-width: 400px) {
    .mCChild {
        height: 100px;
    }

    .mCChildLocked {
        height: 100px;
    }

    .mCImg {
        height: 100px;
    }

    .mCImgLocked {
        height: 90px;
    }
}