.profileWrapper {
    width: 100%;
    background: #f5f5f5;
}

.pbackgroundImg {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.bImg{
    object-fit: cover;
}
.onlineDot{
    font-size: 45px;
    position: absolute;
    transform: translate(-35%,15%);
}
.cRed{
    color: red;
}
.cGreen{
    color: green;
}
.profileInfo {
    width: 90%;
    margin: auto;
    background: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.25);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.13), inset 0px 1px 8px rgba(255, 255, 255, 0.15);
    border-radius: 8px;
    transform: translate(0, -50%);
    padding-left: 1%;
    padding-right: 1%;
}
.addEventBtn{
    width: 100%;
    border: none;
    outline: none;
    background: linear-gradient(to right, #C51453 0%, #FFB238 100%);
    border-radius: 8px;
    color: white;
}

.profileImg {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    border: 3px solid lightgray;
    margin-top: 2%;
    transform: translate(0, -50%);object-fit: cover;
}

.profileTransformUp {
    transform: translate(0, -150%);
}

.profileViewer {
    width: 90%;
    margin: auto;
    background: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.25);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.13), inset 0px 1px 8px rgba(255, 255, 255, 0.15);
    border-radius: 8px;
    transform: translate(0, -120%);
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 3%;
    padding-bottom: 3%;
}

.profileViewerNew {
    width: 90%;
    margin: auto;
    background: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.25);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.13), inset 0px 1px 8px rgba(255, 255, 255, 0.15);
    border-radius: 8px;
    transform: translate(0, -30%);
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 3%;
    padding-bottom: 3%;
}

.profileActivity {
    width: 90%;
    margin: auto;
    background: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.25);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.13), inset 0px 1px 8px rgba(255, 255, 255, 0.15);
    border-radius: 8px;
    transform: translate(0, -5%);
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 3%;
    padding-bottom: 3%;
}

.profileViewerItem2 {
    border-left: 1px solid lightgray;
}

.profileViewerFigure {
    margin-left: 5%;
    font-size: 30px;
}

.profileViewerDesc {
    margin-left: 3%;
}

.profileActivitymainImg {
    width: 100%;
    height: 250px;
    border-radius: 10px;
    object-fit: cover;
}

.profileActivitySubImg {
    width: 100%;
    height: 110px;
    border-radius: 5px;
    object-fit: cover;
}

.profileBadgeWrapper {
    background: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.25);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.13), inset 0px 1px 8px rgba(255, 255, 255, 0.15);
    border-radius: 8px;
    margin-top: 8%;
    padding-left: 2%;
    padding-right: 1%;
    padding-top: 2%;
    padding-bottom: 2%;
}

.profileBadgeImg {
    width: 50px;
    height: 50px;
    margin-right: 2%;
    margin-top: 1%;
    border-radius: 5px;
    object-fit: cover;
}

.prifileBadgeInfoSpan {
    font-size: 25px;
}

.profileVideos {
    background: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.25);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.13), inset 0px 1px 8px rgba(255, 255, 255, 0.15);
    border-radius: 8px;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 2%;
    padding-bottom: 1%;
    width: 90%;
    margin: auto;
    transform: translate(0, 5%);
}

.profileYourVideos {
    width: 100%;
    border-radius: 10px;
    height: 170px;
    object-fit: cover;
}

.profileEvents {
    background: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.25);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.13), inset 0px 1px 8px rgba(255, 255, 255, 0.15);
    border-radius: 8px;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 2%;
    padding-bottom: 1%;
    width: 90%;
    margin: auto;
    transform: translate(0, 10%);
}

.profileVoteIn {
    background: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.25);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.13), inset 0px 1px 8px rgba(255, 255, 255, 0.15);
    border-radius: 8px;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 2%;
    padding-bottom: 1%;
    width: 90%;
    margin: auto;
    margin-bottom: 1rem;
    transform: translate(0, 30%);

}





@media screen and (max-width: 1366px) {}


@media screen and (max-width: 1280px) {}


@media screen and (max-width: 1024px) {
    .profileActivity {
        transform: translate(0, -15%);
    }
}

@media screen and (max-width: 901px) {}

@media screen and (max-width: 800px) {}


@media screen and (max-width: 700px) {}

@media screen and (max-width: 600px) {}

@media screen and (max-width: 575px) {
    .profileInfo {
        width: 90%;
    }

    .profileInfoHead {
        font-size: 18px;
    }

    .profileInfoSubHead {
        font-size: 16px;
    }

    .prfileInfoDesc {
        font-size: 11px;
    }

    .profileTransformUp {
        transform: translate(0, -180%);
    }

    .prfileInfoDescSeeMore {
        transform: translate(0, -10%);
    }

    .profileViewer {
        width: 90%;
        transform: translate(0, -270%);
        padding-left: 1%;
        padding-right: 1%;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .profileViewerNew {
        width: 90%;
        transform: translate(0, -50%);
        padding-left: 3%;
        padding-right: 3%;
        padding-top: 2%;
        padding-bottom: 2%;
    }

    .profileViewerFigure {
        margin-left: 4%;
        font-size: 16px;
    }

    .profileViewerDesc {
        margin-left: 2%;
        font-size: 11px;
    }

    .activitySubPostHead {
        font-size: 13px;
    }

    .activitySubPostDesc {
        font-size: 12px;
    }

    .profileActivity {
        width: 90%;
        transform: translate(0, -7%);
    }

    .profileBadgeWrapper {
        width: 90%;
        margin: auto;
        transform: translate(0, -15%);
    }

    .profileVideos {
        padding-top: 5%;
        transform: translate(0, -2%);
    }

    .profileEvents {
        padding-top: 5%;
        transform: translate(0, 2%);
    }

    .profileVoteIn {
        padding-top: 2%;
        padding-bottom: 5%;
        width: 90%;
        margin: auto;
        transform: translate(0, 22%);
        ;
    }
}

@media screen and (max-width: 380px) {
    .profileInfo {
        width: 90%;
    }

    .profileInfoHead {
        font-size: 15px;
    }

    .profileInfoSubHead {
        font-size: 13px;
    }

    .prfileInfoDesc {
        font-size: 12px;
    }

    .profileTransformUp {
        transform: translate(0, -180%);
    }

    .prfileInfoDescSeeMore {
        transform: translate(0, -15%);
        font-size: 13px;
    }

    .profileViewer {
        width: 90%;
        transform: translate(0, -260%);
        padding-left: 1%;
        padding-right: 1%;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .profileViewerNew {
        width: 90%;
        transform: translate(0, -50%);
        padding-left: 3%;
        padding-right: 3%;
        padding-top: 2%;
        padding-bottom: 2%;
    }

    .profileViewerFigure {
        margin-left: 4%;
        font-size: 13px;
    }

    .profileViewerDesc {
        margin-left: 2%;
        font-size: 11px;
    }

    .jDesc{
        font-size: 14px;
    }

    .activitySubPostHead {
        font-size: 13px;
    }

    .profileActivityDesc{
        font-size: 14px;
    }

    .eventDate{
        font-size: 12px;
    }

    .profileFriendName{
        font-size: 14px;
    }
    .profileFriendNameSpan{
        font-size: 12px;
    }

    .profileActivitySubHead{
        font-size: 14px;
    }

    .activitySubPostDesc {
        font-size: 12px;
    }

    .profileActivity {
        width: 90%;
        transform: translate(0, -7%);
    }

    .profileBadgeWrapper {
        width: 90%;
        margin: auto;
        transform: translate(0, -15%);
    }

    .profileVideos {
        padding-top: 5%;
        transform: translate(0, -2%);
    }

    .profileEvents {
        padding-top: 5%;
        transform: translate(0, 2%);
    }

    .profileVoteIn {
        padding-top: 2%;
        padding-bottom: 5%;
        width: 90%;
        margin: auto;
        transform: translate(0, 22%);
    }
}