.graph__cont {
    width: 100%;
    height: 275px;
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    row-gap: 10px;
}

.graph__cont .marketplace__slider__cont {
    background-color: #FFFFFF;
    padding: 0;
}

.graph__cont select,
.graph select {
    border: 1px solid rgba(255, 255, 255, 0.24);
    border-radius: 4px;
    color: #C51453;
    font-size: 13px;
    padding: 1px;
    box-shadow: 0px 1px 8px rgb(0 0 0 / 10%);
    margin-bottom: 7px;
}

.graph__cont .graph__1 {
    width: calc(100% - 575px);
}

.graph__cont .graph__2 {
    width: 225px;
}

.graph__cont .graph__3 {
    width: 330px;
}

.recharts-xAxis {
    opacity: 0;
}

.graph.custom__card_header>div {
    margin-left: 16px;
}

.graph.custom__card_header span {
    font-size: 13px;
    margin-right: 7px;
}

.graph.custom__card_header select {
    color: #C51453;
}

.graph__card {
    border: 1px solid #FFFFFF;
    box-shadow: 0px 2px 4px 2px rgb(194 192 192 / 25%);
    border-radius: 10px;
    padding: 10px;
}

.graph__cont2 {
    display: flex;
    align-items: center;
    row-gap: 10px;
    column-gap: 10px;
}

.graph__cont2>div:first-child {
    width: 615px;
}

.graph__cont2>div:last-child {
    width: calc(100% - 620px);
}

.graph__cont2 .graph__1 {
    width: calc(100% - 280px);
}

.graph__cont2 .graph__1 {
    width: calc(100% - 380px);
}

.graph__cont2 .graph__3 {
    width: 375px;
}


@media screen and (min-width:992px) {
    .film_investment .graph__3 .recharts-wrapper {
        width: 315px !important;
    }
}

@media screen and (max-width:991px) {

    .graph__cont,
    .graph__cont2 {
        height: auto;
        justify-content: normal;
        flex-direction: column;
    }

    .graph__cont2>div:first-child,
    .graph__cont2>div:last-child,
    .graph__cont2 .graph__1,
    .graph__cont2 .graph__3 {
        width: 100%;
    }

    .graph__cont .graph__1,
    .graph__cont .graph__2,
    .graph__cont .graph__3 {
        width: 100%;
        height: 280px;
    }
}