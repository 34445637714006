.jobWrapper {
  width: 100%;
  padding: 3%;
  background: #f9f9f9;
}

.jtCard {
  background: #ffffff;
  border-radius: 8px;
  padding: 1%;
}

.jImg {
  width: 100px;
  height: 100px;
  border-radius: 100%;
}

.jtName {
  font-size: 15px;
}

.jDot {
  font-size: 50px;
  /*color: red;*/
}

/* .bHead{
    margin-top: 4%;
} */
.btAIR {
  text-align: right;
}

.btAIL {
  text-align: left;
}

.checkCircle {
  color: #981548;
}

.tTasks {
  background: #ffffff;
  border-radius: 8px;
  padding-top: 1%;
  margin-top: 2%;
}

.tTUImg {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  object-fit: cover;
}

.tTUserSpan {
  margin-left: 5%;
}

.tTCheck {
  padding-top: 8%;
}

@media screen and (max-width: 1366px) {
}

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 900px) {
}

@media screen and (max-width: 800px) {
}

@media screen and (max-width: 700px) {
}

@media screen and (max-width: 600px) {
}

@media screen and (max-width: 575px) {
  .tInvite {
    text-align: center;
  }

  .tSelect {
    margin-bottom: 3%;
  }

  .tTHead {
    font-size: 8px;
  }

  .tTDesc {
    font-size: 8px;
  }

  .tTUImg {
    width: 20px;
    height: 20px;
    border-radius: 100%;
  }

  .tTUserSpan {
    font-size: 7px;
  }

  .tTCheck {
    font-size: 15px;
  }
}

@media screen and (max-width: 400px) {
}
