.contact_hero {
    min-height: 600px;
    background-color: #000;
}

.contact_hero_section {
    width: 100%;
    position: relative;
}

.contact_us .home_footer {
    background-color: #000 !important;
    border-top: 1px solid #d6d5d0;
}

.contact_us .footer_phone_email,
.contact_us .footer_inputs,
.contact_us .home_footer .home_btn {
    display: none;
}

.contact_us .social_links {
    margin-bottom: 0;
}

.contact_us .contact_form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 50px 20px 0 20px;
}

.contact_us .contact_form h1 {
    max-width: 600px;
}

.contact_us form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.contact_us form input,
.contact_us form textarea,
.contact_us form select {
    border: 1px solid #FFFFFF;
    border-radius: 50px;
    width: 48%;
    background-color: transparent;
    color: #FFFFFF;
    padding: 16px;
    margin-bottom: 1.5rem;
    font-style: italic;
}

.contact_us form select option {
    color: #000;
}

.contact_us form textarea {
    width: 100%;
    border-radius: 30px;
}

.contact_us form input::placeholder,
.contact_us form select::placeholder,
.contact_us form textarea::placeholder {
    color: #FFFFFF99;
}

@media screen and (max-width:650px) {
    .contact_us .contact_form {
        padding: 40px 0 0 0;
    }

    .contact_us form input,
    .contact_us form textarea,
    .contact_us form select {
        width: 100%;
        padding: 13px 15px;
        margin-bottom: 1rem;
        font-size: 14px;
    }
}