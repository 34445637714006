.leaderboard__cont {
    background: #f9f9f9;
    padding: 10px;
    border-radius: 8px;
}

.leaderboard__cont .top__community {
    padding: 0 !important;
}

.top__community__rank p {
    background: #C51453;
    border-radius: 6px;
    color: #FFFFFF;
    font-weight: 700;
    padding: 2px 5px;
    width: fit-content;
    margin-bottom: 0;
}

@media screen and (max-width: 768px) {
    .top__community__card__body {
        padding: 8px;
    }
}