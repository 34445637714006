.admin__sidebar {
    height: 100%;
    position: fixed;
    z-index: 100;
    top: 0;
    left: -300px;
    width: 300px;
    max-width: 300px;
    min-width: 300px;
    padding: 15px 20px;
    background: linear-gradient(180deg, #C51453 0%, #FFB238 100%);
    white-space: nowrap;
    overflow-x: hidden;
    transition: 0.4s all ease, 0.4s left ease;

}

.admin__sidebar .sidebar__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.admin__sidebar .sidebar__header .sidebar__logo img {
    height: 45px;
}

.admin__sidebar .sidebar__header .sidebar__close {
    display: none;
}

.admin__sidebar .sidebar__header .sidebar__close svg {
    font-size: 26px;
    margin-top: 6px;
    cursor: pointer;
}

.admin__sidebar .sidebar__header .sidebar__close path {
    fill: #FFFFFF;
}

.admin__sidebar .sidebar__links {
    margin-top: 20px;
}

.admin__sidebar .sidebar__links li {
    list-style: none;
    padding: 15px 0;
}

.admin__sidebar .sidebar__links li a {
    display: flex;
    align-items: center;
    color: #FFFFFF;
    cursor: pointer;
    transition: all 0.5s ease-out;
}

.admin__sidebar .sidebar__links li a svg {
    font-size: 20px;
    margin-right: 10px;
    width: 20px;
}

.admin__sidebar .sidebar__links li a span {
    margin-top: 3px;
}

@media screen and (max-width: 992px) {
    .admin__sidebar .sidebar__header .sidebar__close {
        display: block;
    }

    .admin__sidebar__left {
        left: 0px !important;
    }
}