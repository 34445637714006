.content__slider__cont {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 8px;
}

.content__slider__cont .react-multiple-carousel__arrow {
  background: rgba(255, 255, 255, 0.68);
}

.content__slider__cont .react-multiple-carousel__arrow::before {
  color: #c51453;
  font-weight: 600;
}

.content__slider__cont .content__slider__item {
  width: 96%;
  height: 120px;
  border-radius: 4px;
  overflow: hidden;
  display: block;
}

.content__slider__cont .content__slider__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dashboard__content__library
  .marketplace__slider__cont
  .marketplace__card
  .marketplace__card__img {
  height: 130px;
}

.dashboard__content__library .marketplace__slider__cont .swiper-slide,
.dashboard__content__library .marketplace__slider__cont .marketplace__card {
  width: 160px;
}

.dashboard__content__library .swiper-slide .custom__card {
  background: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
  display: block !important;
  color: black !important;
  text-decoration: none !important;
}

.dashboard__content__library .swiper-slide .marketplace__card__title {
  font-size: 14px !important;
}

@media screen and (max-width: 768px) {
  .content__slider__cont .content__slider__item {
    width: 100%;
    height: 175px;
  }
}
