.leaderboard__wrapper .leaderboard__users {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.2rem;
}

.leaderborad__img {
    width: 45px;
    height: 45px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
}

.leaderborad__img img {
    border-radius: 6px;
    object-fit: cover;
    width: 100%;
}

.leaderborad__img p {
    font-size: 12px;
}

.leaderborad__img .leaderboard__rank {
    position: absolute;
    background: #C51453;
    border-radius: 6px;
    color: #FFFFFF;
    font-weight: 600;
    padding: 2px 4px;
    bottom: -12px;
    right: -7px;
}