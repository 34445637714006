.community__cont {
    position: relative;
}

.community__cont .community__feed__discover .d-flex {
    cursor: pointer;
}

.community__cont .community__feed__discover p {
    font-size: 14px;
    margin-left: 8px;
    transition: 0.3s color ease;
}

.community__cont .community__feed__discover .d-flex:hover p {
    color: #C51453;
}

.community__cont .community__feed__discover svg {
    width: 16px;
    height: 16px;
}

.community__cont .community__feed__discover path {
    fill: #C51453;
}

.community__feed__discover__sep {
    margin: 1.25rem 0;
    border: 1px solid rgba(187, 187, 187, 0.17);
    width: 100%;
}

.community__cont .loginBtn {
    height: 38px;
    font-size: 14px;
    background: #C51453;
}

.community__side__card {
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
}

.community__side__card__content {
    overflow: hidden;
}

.community__side__card img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 4px;
    margin-right: 8px;
}

.community__side__card .community__side__card__content p {
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.community__side__card .community__side__card__content span {
    font-size: 12px;
    color: gray;
}

.top__community__card__body {
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 14px;
}

.top__community__item_cont {
    padding: 9px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(187, 187, 187, 0.17);
}

.top__community__item_cont .loginBtn {
    font-size: 13px;
    height: 28px;
    width: 60px;
    min-width: 60px;
    background: #C51453;
}

.top__community__item {
    overflow: hidden;
    display: flex;
    align-items: center;
}

.top__community__item .top__community__rank span {
    margin-right: 6px;
    font-size: 13px;
    font-weight: 600;
}

.top__community__item .top__community__rank svg {
    margin-right: 10px;
}

.top__community__item .top__community__rank path {
    fill: #08cb08;
}

.community__cont .dashboard__child2 {
    display: block !important;
}

.community__cont .dashboard__child2>div {
    display: flex;
    flex-direction: column;
    row-gap: 14px;
}

.community__cont .marketplace__slider__cont .marketplace__card .marketplace__card__img {
    height: 150px;
}

@media screen and (min-width:992px) {
    .community__cont {
        padding-left: 260px;
    }

    .community__cont .dashboard__child2 {
        position: fixed;
        height: calc(100vh - 96px);
        overflow-y: auto;
        left: 1rem;
    }

    .community__cont .dashboard__child1 {
        width: 100% !important;
    }
}