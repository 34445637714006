a{
    text-transform: none;
    text-decoration: none;
}
.loginWrapper{
    width: 100%;
    min-height: 100vh;
    height: 100%;
    padding: 3rem 0;
    background: linear-gradient(120deg, #C51453 0%, #FFB238 100%);

}
.loginDiv{
    width: 45%;
    /* height: 75%; */
    background: white;
    border-radius: 20px;
    padding: 3%;
    overflow-x: hidden;
    overflow-y: auto;
}
.loginLogo{
    width: 20%;
    /*height: 50%;*/
}

@media screen and (max-width: 900px) {

    .loginDiv{
        width: 90%;
        height: 80%;
        background: white;
        border-radius: 20px;
        padding: 4%;
    }
}