.wdWrapper {
    /*height: 50vh;*/
    background: linear-gradient(120deg, #C51453 0%, #FFB238 100%);
    padding-bottom: 5%;
}

.wdParent1 {
    width: 100%;
    display: flex;
}

.wdChild11 {
    width: 10%;
    height: 50px;
    padding: 1%;
    /*background: lightgreen;*/
}

.wdChild12 {
    width: 80%;
    height: 50px;
    /*background: lightseagreen;*/
}

.wdChild13 {
    width: 10%;
    height: 50px;
    padding: 1%;
    /*background: lightcoral;*/
}

.walletHeaderIcons {
    font-size: 180%;
    color: white;
}

.walletHeaderSelect {
    width: 15%;
    height: 50px;
    outline: none;
    border: none;
    font-weight: bold;
    font-size: 120%;
    /*color: white;*/
    background: transparent;
    color: white;
}

.walletHeaderSelectOption {
    background: gray;
}

.cryptoInfoWrapper {
    width: 100%;
    color: white;
    margin-top: 3%;
}

.ciText1 {
    width: 100%;
    text-align: center;
    padding: 0.5%;
}


.wcurrency {
    font-weight: bold;
    font-size: 100%;
    color: white;

}

.wamt {
    font-weight: bold;
    font-size: 150%;
    color: white;
    letter-spacing: 8px;

}

.wunit {
    font-weight: bold;
    font-size: 100%;
    color: white;
}

.wa {
    margin-top: 2%;
}

.transferIconWrapper {
    margin-top: 3%;
    width: 100%;
}

.tiIcon1 {
    border: 2px solid white;
    width: 80px;
    height: 80px;
    border-radius: 100px;
    color: white;
    margin-right: 3%;
    padding: 1%;
    cursor: pointer;
}

.tiIcon2 {
    border: 2px solid white;
    width: 80px;
    height: 80px;
    border-radius: 100px;
    color: white;
    margin-right: 3%;
    padding: 1%;
    cursor: pointer;
}

.tiShareIcons1 {
    font-size: 290%;
}

.tiShareIcons2 {
    font-size: 290%;
    transform: rotate(180deg) scaleX(-1);
}

.tiInfo1 {
    font-size: 120%;
    font-weight: bold;
    padding-top: 20%;
}

.tiInfo2 {
    font-size: 120%;
    font-weight: bold;
    padding-top: 20%;
    transform: translate(-20%, 0);
}

.cryptoAssetsWrapper {
    width: 100%;
    background: #F9F9F9;
    padding: 3%;
}

.caItemParent {
    width: 100%;
    background: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.25);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.13), inset 0px 1px 8px rgba(255, 255, 255, 0.15);
    border-radius: 8px;
    display: flex;
    padding: 1%;
    margin-top: 2%;
}

.caiChild11 {
    width: 15%;
    height: 60px;
}

.caiChild12 {
    padding: 1%;
    width: 50%;
    height: 60px;
}

.caiChild13 {
    width: 35%;
    height: 60px;
    padding: 0.5%;
    text-align: right;
}

.caiImg {
    width: 60px;
    height: 60px;
    border-radius: 100%;
}

.caiImgDesc1 {
    font-weight: bold;
}

.caiIcon {
    margin-top: 2%;
    font-size: 200%;
}








@media screen and (max-width: 1366px) {
    .cryptoInfoWrapper {
        margin-top: 1%;
    }

    .wa {
        margin-top: 1%;
    }
}


@media screen and (max-width: 1280px) {}


@media screen and (max-width: 1024px) {}

@media screen and (max-width: 900px) {
    .wdWrapper {
        padding-bottom: 12%;
    }
}


@media screen and (max-width: 700px) {}


@media screen and (max-width: 575px) {
    .wdChild11 {
        width: 15%;
        padding: 2%;
        height: 40px;
    }

    .wdChild12 {
        width: 70%;
        height: 40px;
    }

    .wdChild13 {
        width: 15%;
        padding: 2%;
        height: 40px;
    }

    .walletHeaderIcons {
        font-size: 150%;
    }

    .walletHeaderSelect {
        width: 40%;
        height: 40px;
        font-size: 100%;
    }

    .cryptoInfoWrapper {
        width: 100%;
        color: white;
        margin-top: 5%;
    }

    .ciText1 {
        font-size: 110%;
        width: 100%;
        text-align: center;
        padding: 1.5%;
        /*border-right: 2px solid white;*/
    }

    .ciText2 {
        font-size: 110%;
        width: 50%;
        padding: 1.5%;
    }

    .wcurrency {
        font-size: 90%;
    }

    .wamt {
        font-size: 120%;
        letter-spacing: 3px;

    }

    .wunit {
        font-weight: bold;
        font-size: 90%;
        color: white;
    }

    .transferIconWrapper {
        margin-top: 9%;
        width: 100%;
    }

    .tiIcon1 {
        border: 2px solid white;
        width: 70px;
        height: 70px;
        border-radius: 100px;
        color: white;
        margin-right: 4%;
        padding: 2%;
    }

    .tiIcon2 {
        border: 2px solid white;
        width: 70px;
        height: 70px;
        border-radius: 100px;
        color: white;
        margin-right: 4%;
        padding: 2%;
    }

    .tiShareIcons1 {
        font-size: 290%;
    }

    .tiShareIcons2 {
        font-size: 290%;
        transform: rotate(180deg) scaleX(-1);
    }

    .tiInfo1 {
        font-size: 100%;
        font-weight: bold;
        padding-top: 20%;
    }

    .tiInfo2 {
        font-size: 100%;
        font-weight: bold;
        padding-top: 20%;
        transform: translate(-20%, 0);
    }

    .caiChild11 {
        width: 15%;

        height: 50px;
    }

    .caiChild12 {
        padding: 4%;
        width: 50%;

        height: 50px;
    }

    .caiChild13 {
        width: 35%;

        height: 50px;
        padding: 0.5%;
        text-align: right;
    }

    .caiImg {
        width: 50px;
        height: 50px;
        border-radius: 100%;
    }

    .caiImgDesc1 {
        font-weight: bold;
    }

    .caiIcon {
        margin-top: 5%;
        font-size: 200%;
    }

    .caiName {
        font-size: 110%;

    }
}


@media screen and (max-width: 400px) {}