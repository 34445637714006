@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "Open Sans", sans-serif !important;
}

a {
  text-transform: none;
  text-decoration: none;
}

input,
button,
input:is(:active, :focus),
select:is(:active, :focus) {
  outline: 0;
  border: 0;
}

input::placeholder {
  color: #00000069;
}

.loginBtn {
  width: 100%;
  height: 50px;
  font-weight: 500;
  font-size: 120%;
  border: none;
  outline: none;
  background: linear-gradient(to right, #c51453 0%, #ffb238 100%);
  border-radius: 8px;
  color: white;
  cursor: pointer;
}

.googleLogo {
  color: green;
  border-radius: 100px;
  border: 2px solid lightgray;
  padding: 3%;
  cursor: pointer;
  width: 15px;
  height: 15px;
}

.twitterLogo {
  color: deepskyblue;
  border-radius: 100px;
  border: 2px solid lightgray;
  padding: 3%;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.appleLogo {
  color: black;
  border-radius: 100px;
  border: 2px solid lightgray;
  padding: 3%;
  cursor: pointer;
  width: 15px;
  height: 15px;
}

.customTextMuted {
  color: #b1b1b1;
}

.cursorStyle {
  cursor: pointer;
}

.blue {
  color: #981548;
}

.fa-sun,
.fa-moon {
  cursor: pointer;
  font-size: 20px;
  margin-right: 8px;
  color: #c51453;
}

.green {
  color: #981548;
}

@media screen and (max-width: 900px) {
  .loginBtn {
    height: 45px;
  }
}

.custom__card {
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
}

.custom__card .custom__card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  font-weight: 600;
}

.custom__card .custom__card__header p {
  font-weight: 600;
}

.custom__card .custom__card__header a {
  font-weight: 500;
}

.icon__wrapper {
  border: 1px solid #ebebeb;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  width: fit-content;
}

.icon__wrapper i,
.icon__wrapper svg {
  width: 27px;
  height: 27px;
  color: #c51453;
}

/* dark Mode  */
body.dark,
body.dark .custom__card,
body.dark .profileInfo,
body.dark .content__slider__cont,
body.dark .dashboard__messages__wrapper .messages__body,
body.dark .marketplace__slider__cont,
body.dark .list-group-item,
body.dark .cmContentWrapper .card-body,
body.dark .recruitmentWrapper .card-body,
body.dark .quick__upload__cont .quick__upload__btn,
body.dark .quick__upload__cont .quick__upload__pic,
body.dark .sort__filter {
  background-color: #230c1d !important;
}

body.dark .dashboard .dashboard__cont > div,
body.dark .leaderboard_modal .modal-header,
body.dark .leaderboard_modal .modal-content,
body.dark .top__community__card__body,
body.dark .recruitmntInfoPanels,
body.dark .recruitmntDiv,
body.dark .contentDivBackground,
body.dark .recruitmntInfoDiv,
body.dark .chat__body,
body.dark .jtCard,
body.dark .feed__cont .community__input,
body.dark .dashboard__community__feed__wrapper .feed__card .feed__content {
  background-color: #12060f !important;
}

body.dark .dashboard__messages__wrapper > .custom__card,
body.dark .dashboard__content__library .marketplace__slider > .custom__card,
body.dark .dashboard__content__library > .custom__card,
body.dark .itForm .form-control,
body.dark .dashboard__messages__wrapper .messages__body .message__row,
body.dark .quick__upload__cont .custom__card,
body.dark .input__filter {
  background-color: #070206 !important;
}

body.dark .cryptoAssetsWrapper,
body.dark .demo_modal .modal-content,
body.dark .recruitmentWrapper,
body.dark .contentManagmentWrapper,
body.dark .dashboard__panel {
  background-color: #230c1d !important;
  color: #ffffffb8 !important;
}

body.dark .header__nav .nav__item span,
body.dark .wallet__cont p,
body.dark .dashboard__analytics .card__top__wrapper p,
body.dark .affiliate__cont .affiliate__table table,
body.dark .chat__header__info,
body.dark .cmfwHead2Select,
body.dark .list-group-item,
body.dark .profileInfo,
body.dark .profileViewer,
body.dark .profileViewerNew,
body.dark .profileActivity,
body.dark .profileBadgeWrapper,
body.dark .profileVideos,
body.dark .profileEvents,
body.dark .profileVoteIn,
body.dark .tTasks,
body.dark .jobWrapper,
body.dark .form-select,
body.dark .leaderboard_modal .modal-header,
body.dark .itForm .form-control,
body.dark .chat__content__child p,
body.dark .header__cont .header__avatar span,
body.dark .top__community__card__body,
body.dark .marketplace__card__title,
body.dark .dashboard__messages__wrapper .messages__body {
  color: #ffffffb8 !important;
}

body.dark .cmfwHead2Select,
body.dark .leaderboard__cont,
body.dark .profileWrapper,
body.dark .tTasks,
body.dark .form-select {
  background: rgb(80 50 74) !important;
}

body.dark .chat__right__profile .chat__right__sep,
body.dark .chat__footer form,
body.dark .chat__cont .chat__child1 .chat__list .chat__list__sep {
  border-color: rgb(80 50 74) !important;
}

body.dark .chat__content__child p,
body.dark .graph__cont select,
body.dark .graph select,
body.dark .profileViewer,
body.dark .profileViewerNew,
body.dark .profileActivity,
body.dark .profileBadgeWrapper,
body.dark .profileVideos,
body.dark .profileEvents,
body.dark .profileVoteIn,
body.dark .jobWrapper {
  background: #230c1de8 !important;
}

body.dark .chat__body__right p {
  background: #c51453 !important;
}

body.dark .chat__footer input,
body.dark .chat__footer input::placeholder,
body.dark .community__create__wrapper .community__input,
body.dark .community__create__wrapper .community__input input,
body.dark .community__create__wrapper .community__input input::placeholder,
body.dark .chat__cont .chat__child1 .chat__list__search input,
body.dark .chat__cont .chat__child1 .chat__list__search input::placeholder,
body.dark .input-group > .form-control,
body.dark .input-group > .form-control::placeholder,
body.dark .input__filter input,
body.dark .input__filter input::placeholder {
  background-color: transparent;
  color: #ffffffb8 !important;
}

body.dark .quick__upload__cont .quick__upload__btn,
body.dark .chat__header,
body.dark .recruitmentJobs img,
body.dark .chat__footer {
  border-color: transparent !important;
  border-bottom: 0 !important;
}

body.dark .graph__card {
  border: none;
  box-shadow: none;
}

body.dark .card {
  border: 0;
  overflow: hidden;
}

body.dark .form-control,
body.dark .form-control:focus,
body.dark .form-control:active {
  border: 1px solid #605867;
  box-shadow: none;
}

body.dark .modal-header {
  border-bottom-color: #230c1d !important;
}

body.dark .marketplace__cont .marketplace__slider__cont .marketplace__card {
  background-color: transparent !important;
  box-shadow: none !important;
}

.header_logo1 {
  display: initial !important;
}

.header_logo2 {
  display: none !important;
}

body.dark .header_logo1 {
  display: none !important;
}

body.dark .header_logo2 {
  display: initial !important;
}

body.dark .filter_wrapper p,
body.dark .header__nav .nav__item > div {
  background: #c51453;
  border: 1px solid rgba(255, 255, 255, 0.25);
}

body.dark .filter_wrapper p path,
body.dark .icon__wrapper path,
body.dark .icon__wrapper i {
  color: rgba(255, 255, 255, 0.52);
  fill: rgba(255, 255, 255, 0.52);
}

body.dark .level__pro,
body.dark .mission__body .icon__wrapper {
  background: #070206;
  border: 0;
}

body.dark .missionBadgeName {
  color: #cfcfcf;
}
