.homepage {
	width: 100%;
	color: #ffffff;
	font-family: 'Montserrat', sans-serif !important;
	position: relative;
}

.background_image {
	background-color: #000;
	/* background-image: url('../../../assets/website/dreamlight.png'); */
	left: 0;
	min-height: 100vh;
	min-width: 100vw;
	position: fixed;
	top: 0;
	z-index: -1;
}

.homepage .p_tb {
	padding: 60px 0;
}

.home_hero {
	height: 100vh;
	min-height: 600px;
	background-image: url('../../../assets/website/hero.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.home_hero_section {
	height: calc(100% - 137px);
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding-left: 40px !important;
	padding-right: 40px !important;
	position: relative;
}

.scroll_down {
	position: absolute;
	letter-spacing: 0.3em;
	color: #ffffff;
	transform: rotate(-90deg);
	margin-bottom: 0;
	right: -50px;
}

.homepage .hero_title {
	font-size: 80px;
	max-width: 705px;
	line-height: 1;
	/* margin-bottom: 0 !important; */
}

.homepage .hero_title span {
	font-weight: 800;
	color: #ee9821;
}

.homepage .hero_subtitle {
	font-size: 26px;
}

@media screen and (max-width: 1400px) {
	.home_hero_section {
		height: calc(100% - 114px);
	}

	.homepage .hero_subtitle {
		font-size: 18px;
	}

	.scroll_down {
		font-size: 14px;
		right: -60px;
	}
}

@media screen and (max-width: 768px) {
	.home_hero {
		height: 600px;
		max-height: 600px;
	}

	.homepage .p_tb {
		padding: 36px 0;
	}

	.home_hero_section {
		padding-left: 20px !important;
		padding-right: 20px !important;
		height: calc(100% - 94px);
	}

	.homepage .hero_title {
		font-size: 48px;
		/* max-width: 340px; */
	}

	.homepage .hero_subtitle {
		font-size: 16px;
		max-width: 260px;
	}

	.scroll_down {
		font-size: 14px;
		right: -70px;
	}
}
