.dreambird__tips {
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(180deg, #c51453 0%, #ffb238 100%);
  text-align: center;
  min-height: 100%;
  row-gap: 20px;
}

.dreambird__tips .tips__title {
  font-size: 15px;
}

.dreambird__tips .tips__subtitle {
  font-size: 13px;
}

.dreambird__tips .tips__learn {
  font-size: 12px;
  background: rgba(255, 255, 255, 0.69);
  border: 1px solid rgba(255, 255, 255, 0.24);
  border-radius: 4px;
  color: #c51453;
  padding: 0 7px;
  cursor: pointer;
}

.dreambird__tips .tips__logo {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
}

@media screen and (min-width: 992px) {
  .col-lg-3.tips {
    width: 31%;
  }
}
