.demo_modal .modal-content {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
}

.demo_modal .modal-content .bi::before {
  font-weight: 800 !important;
  cursor: pointer;
}

.demo_modal .modal-body .b_left {
  border-left: 1px solid gray;
}

.demo_modal .modal-body a {
  display: block;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
  color: #C51453;
  padding: 10px 0;
}
