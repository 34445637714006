.community__forum .dashboard__child1 {
  width: calc(100% - 360px) !important;
}

.community__forum .dashboard__child1 > div {
  max-width: 700px;
  margin: 0 auto;
}

.community__forum .dashboard__child1 .feed__content {
  background: white;
  border-radius: 8px;
}

.community__forum .dashboard__child1 .feed__card {
  min-height: 210px;
  height: auto !important;
}

.community__forum .dashboard__child1 .feed__title img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100%;
  margin-right: 8px;
}

.community__forum .dashboard__child1 .feed__title {
  display: flex;
  align-items: center;
}

.community__forum .dashboard__child1 .feed__title p {
  color: gray;
  font-size: 12px;
  font-weight: 400;
}

.community__forum .feed__content__sep {
  /* border: 1px solid #f9f9f9; */
  border: 0;
  margin: 14px 0 !important;
}

.community__forum .community__about .community__about__info {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.community__forum .community__about .community__about__info img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100%;
  margin-right: 8px;
}

.community__forum .community__about .community__about__info p {
  font-size: 14px;
  font-weight: 600;
}

.community__forum .community__about .community__about__created {
  color: gray;
  font-size: 13px;
  margin-top: 6px;
}

.community__forum .community__about .community__about__stats {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.community__forum .community__about .community__about__stats > div div {
  font-size: 16px;
  font-weight: 600;
}

.community__forum .community__about .community__about__stats > div p {
  font-size: 13px;
  color: gray;
}

.community__forum .dashboard__child2 {
  width: 350px !important;
}

.community__forum .comments__body {
  padding: 5px 8px 10px 8px;
}

.community__forum .comments__body .comments__parent {
  display: flex;
  /* align-items: center; */
}

.community__forum .comments__body img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100%;
  margin-right: 14px;
}

.community__forum .comments__body .comments__wrapper .comment {
  display: flex;
  align-items: center;
}

.community__forum .comments__body .comments__wrapper .comment div {
  font-size: 14px;
  margin-right: 10px;
  font-weight: 500;
}

.community__forum .comments__body .comments__wrapper .comment span {
  font-size: 10px;
  color: gray;
  line-height: 2px;
}

.community__forum .comments__body .comments__wrapper .comment__action {
  display: flex;
  margin-top: 4px;
}

.community__forum .comments__body .comments__wrapper .comment__action p {
  font-size: 12px;
  color: #c51453;
  cursor: pointer;
  font-weight: 700;
}

.community__forum .comments__body .comment__reply {
  padding: 7px 0 0 30px;
}

@media screen and (max-width: 991px) {
  .community__forum {
    flex-direction: column-reverse !important;
  }

  .community__forum .dashboard__child2 > div {
    max-width: 700px;
    margin: 0 auto;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .community__forum .dashboard__child1 .feed__card .feed___img {
    min-height: 420px;
  }
}
