

.vscSubHead1 {
    transform: translate(0, 18%);
    cursor: pointer;
    padding: 1.5%;

}

.vscSubHead2 {
    transform: translate(0, 18%);
    cursor: pointer;
    padding: 1.5%;
    margin-left: 4%;

}

.vscSubHead1Highlighted {
    border-bottom: 2px solid white;
    transform: translate(0, 18%);
    padding: 1.5%;
    cursor: pointer;
}

.vscSubHead2Highlighted {
    margin-left: 4%;
    border-bottom: 2px solid white;
    transform: translate(0, 18%);
    padding: 1.5%;
    cursor: pointer;
}

.vscCategory {
    width: 100%;
    display: flex;
    overflow: auto;
}

.vscCateItem {
    margin-right: 10%;
}

.vscCateItemImg {
    width: 100%;
    height: 180px;
    object-fit: cover;
}


@media screen and (max-width: 900px) {
    .vscSubHead2 {
        margin-left: 6%;
    }
    .vscSubHead2Highlighted {
        margin-left: 6%;
    }



    .vscSubHead1 {
        transform: translate(0, 30%);

    }

    .vscSubHead2 {
        transform: translate(0, 30%);

    }

    .vscSubHead1Highlighted {
        transform: translate(0, 30%);
    }

    .vscSubHead2Highlighted {
        transform: translate(0, 30%);
    }
}