.marketplace__cont {
    width: 100%;
}

.marketplace__slider__cont {
    border-radius: 8px;
    padding: 14px;
    background: #F9F9F9;
}

.marketplace__slider__cont .swiper-slide,
.marketplace__slider__cont .marketplace__card {
    width: 280px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    color: #c51453;
    font-weight: bolder;
}

.swiper-button-next,
.swiper-button-prev {
    width: 60px;
    border-radius: 100%;
    height: 60px;
    text-align: center;
    background: #00000033;
}

.marketplace__slider__cont .marketplace__card .marketplace__card__img {
    height: 330px;
    /* height: 150px; */
    border-radius: 8px;
    overflow: hidden;
}

.marketplace__slider__cont .marketplace__card .marketplace__card__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.marketplace__card__body {
    padding: 8px 0;
}

.marketplace__card__body p {
    margin-bottom: 0;
}

.marketplace__card__body .marketplace__card__title {
    font-size: 16px;
    margin-bottom: .5rem !important;
    font-weight: 500;
}

.marketplace__card__body .marketplace__card__by {
    font-size: 12px;
    color: #707070;
}

.marketplace__slider_sep {
    margin: 2.5rem 0;
}

.marketplace__cont .marketplace__slider__cont .marketplace__card {
    background-color: transparent;
    box-shadow: none;
}

@media screen and (max-width: 768px) {
    .marketplace__slider__cont {
        padding: 10px;
    }
}