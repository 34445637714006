.chat__popup {
    position: fixed;
    bottom: 0px;
    right: 17px;
    z-index: 1001;
    height: 400px;
}

.chat__popup .icon__wrapper {
    padding: 2px 6px;
}

.chat__popup .icon__wrapper i::before {
    font-weight: bolder !important;
}

.chat__popup__hide {
    bottom: -338px;
}

.chat__popup .chat__body {
    height: calc(100% - 54px - 51px);
}

.chat__pop__header__icons {
    display: flex;
    align-items: center;
}

.chat__pop__header__icons .icon__wrapper {
    width: 28px;
}

.chat__pop__header__icons .icon__wrapper:first-child {
    margin-right: 8px;
}

@media screen and (max-width:700px) {
    .chat__popup {
        display: none;
    }
}