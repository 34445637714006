.quick__upload__cont {
    display: flex;
}

.quick__upload__cont .quick__upload__subtitle {
    font-size: 12px;
    margin-bottom: 4px !important;
}

.quick__upload__cont .quick__upload__pic {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    background-color: #F9F9F9;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.quick__upload__cont .quick__upload__pic img {
    width: 75%;
}

.quick__upload__cont .quick__upload__btn {
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 5px;
}

.quick__upload__cont .quick__upload__btn p {
    background: #C51453;
    border-radius: 8px;
    padding: 7px 11px;
    color: #FFFFFF;
    font-size: 14px;
    cursor: pointer;
}

.quick__upload__cont input[type="file"] {
    display: none;
}

.quick__upload__cont .col-md-8 {
    padding-right: 7px;
}

@media screen and (max-width: 768px) {
    .quick__upload__cont {
        flex-direction: column;
        row-gap: 14px;
    }

    .quick__upload__cont .quick__upload__pic {
        width: 145px;
        height: 145px;
    }
}