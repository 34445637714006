.dashboard__messages__wrapper .unread__messages {
    background: #C51453;
    color: #FFFFFF;
    border-radius: 8px;
    padding: 4px 9px;
    text-align: center;
    margin-left: 10px;
}

.dashboard__messages__wrapper .messages__body {
    background: #F9F9F9;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.dashboard__messages__wrapper .messages__body .message__row {
    background: white;
    padding: 8px;
    border-radius: 8px;
    display: flex;
    font-size: 11px;
}

.dashboard__messages__wrapper .messages__body .message__row .col-3 {
    font-weight: 600;
}

.dashboard__messages__wrapper .messages__body .message__row .col-2,
.dashboard__messages__wrapper .messages__body .message__row .col-3,
.dashboard__messages__wrapper .messages__body .message__row .col-5 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dashboard__messages__wrapper .messages__body .message__row .message__tag {
    text-align: center;
    background-color: #066DB9;
    color: #FFFFFF;
    margin: 0 4px;
    border-radius: 5px;
}

.dashboard__messages__wrapper .messages__body .message__row .message__unread {
    text-align: center;
    background-color: #767676;
    color: #FFFFFF;
    border-radius: 5px;
}