.offerdetail {
    width: 100%;
    height: 150px;
    background: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.25);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.13), inset 0px 1px 8px rgba(255, 255, 255, 0.15);
    border-radius: 8px;
}

.offerDetailText {
    font-size: 75%;
}

.offerImg {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.offer3dlogo {
    width: 100%;
    height: 150px;
    background: rgba(33, 67, 227, 0.14);
    border: 0.999613px solid #798DEB;
}

.offer3DlogoImage {
    width: 90%;
}

.offerSide1 {
    position: absolute;
    right: 0;
    transform: translateY(-50px);
}

.offerSide2 {
    position: absolute;
    right: 0;
    transform: translateY(-130px);
}


@media screen and (max-width: 900px) {}

@media screen and (max-width: 500px) {
    .offerdetail {
        height: 180px;
    }

    .offerImg {
        height: 180px;
    }

    .offer3dlogo {
        height: 180px;
    }

}