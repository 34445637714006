.homeFooterWrapper {
  position: relative;
}

.homeFooterWrapper.pb {
  padding-bottom: 9rem;
}

.line1 {
  position: absolute;
  left: 0;
  top: -4px;
  z-index: 0;
}

.line2 {
  position: absolute;
  right: 0;
  top: 10px;
  z-index: 0;
}

.line3 {
  position: absolute;
  left: -23px;
  top: -19px;
  z-index: 0;
}

.line4 {
  position: absolute;
  left: 0px;
  bottom: 33px;
  z-index: 0;
}

.line5 {
  position: absolute;
  left: -43px;
  bottom: -109px;
  z-index: 0;
}

.line6 {
  position: absolute;
  right: 0;
  bottom: 220px;
  z-index: 0;
}

.line7 {
  position: absolute;
  right: 0;
  bottom: 6px;
  z-index: 0;
}

.splash_back {
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.25);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.13),
    inset 0px 1px 8px rgba(255, 255, 255, 0.15);
  display: flex;
  column-gap: 12px;
  align-items: center;
  border-radius: 8px;
  width: fit-content;
  padding: 9px 14px;
  font-size: 14px;
  cursor: pointer;
  position: relative;
}

.splash_back .back_icon {
  min-width: 28px;
  height: 28px;
  display: grid;
  place-items: center;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.68);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.splash_banner {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  position: relative;
}

.splash_banner > div:first-child {
  width: 45%;
  position: relative;
}

.splash_banner .poster_img {
  width: 100%;
  object-fit: cover;
  margin-left: -6px;
  position: relative;
  z-index: 11;
}

.splash_banner > div:last-child {
  width: 55%;
  padding-top: 5px;
  position: relative;
  z-index: 11;
}

.splash_title {
  font-size: 32px;
  margin-bottom: 6px;
  color: #efefef;
}

.splash_video_details {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 10px;
  column-gap: 1.4rem;
  margin-bottom: 1.8rem;
  color: #b1b1b1;
  font-size: 14px;
}

.splash_likes_views {
  display: flex;
  align-items: center;
  column-gap: 1.3rem;
  flex-wrap: wrap;
  row-gap: 10px;
}

.top_btn {
  border: 1px solid #ffffff;
  border-radius: 6px;
  padding: 4px 17px;
  font-weight: 600;
}

.like_btn {
  width: 34px;
  height: 34px;
  display: grid;
  place-items: center;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.13);
}

.splash_likes_views span {
  color: #b1b1b1;
}

.splash_video_desc {
  margin: 1rem 0;
}

.splash_genre {
  display: flex;
  align-items: center;
  column-gap: 1.2rem;
}

.splash_genre > p {
  border: 1px solid #ffffff;
  border-radius: 10px;
  font-size: 14px;
  margin-bottom: 0;
  padding: 2px 10px;
}

.splash_watch_ads {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1.5rem;
  margin: 3rem 0 5rem 0;
}

.splash_watch_ads .swa {
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.25);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.13),
    inset 0px 1px 8px rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  display: flex;
  align-items: center;
  column-gap: 12px;
  padding: 14px 28px;
  position: relative;
}

.splash_watch_ads .swa p {
  white-space: nowrap;
  position: absolute;
  margin-bottom: 0;
  font-size: 12px;
  color: #b1b1b1;
  bottom: -28px;
  left: 0px;
}

.splash_watch_ads .swa > div:first-child {
  text-align: center;
}

.splash_watch_ads .swa > div:first-child span {
  font-size: 13px;
}

.swa_icon {
  min-width: 50px;
  height: 50px;
  display: grid;
  place-items: center;
  background: #c51453;
  border-radius: 8px;
}

.swa_2 {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.swa_2 > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.swa_2 .swa_icon {
  min-width: 36px;
  height: 36px;
}

.splash_trailer {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.13),
    inset 0px 1px 8px rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 16px 20px;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  position: relative;
}

.splash_trailer > div {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  font-size: 14px;
  position: relative;
}

.splash_trailer > div img {
  aspect-ratio: 3/2;
  object-fit: cover;
  position: relative;
}

.splash_party {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.13),
    inset 0px 1px 8px rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: rgba(255, 255, 255, 0.08);
  border-radius: 8px;
  padding: 16px 20px;
  display: flex;
  gap: 1.7rem;
  width: fit-content;
  margin-left: auto;
  margin-top: 2.6rem;
  position: relative;
}

.splash_party > div:first-child p {
  max-width: 256px;
  margin-bottom: 0;
}

.splash_party > div:nth-child(2) p {
  text-align: center;
}

.splash_party > div:last-child {
  position: absolute;
  background: linear-gradient(180deg, #c51453 0%, #ffb238 100%);
  border-radius: 8px;
  transform: rotate(-20deg);
  text-align: center;
  padding: 1.1rem;
  left: 79px;
  bottom: -109px;
}

.splash_party > div:last-child p {
  margin-bottom: 2px;
}

.splash_party > div:last-child span {
  font-size: 14px;
  max-width: 178px;
  display: block;
  margin: 0 auto;
}

.splash_vote {
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.25);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.13),
    inset 0px 1px 8px rgba(255, 255, 255, 0.15);
  padding: 32px 20px 32px 200px;
  border-radius: 8px;
  width: fit-content;
  margin-top: 2.2rem;
  position: relative;
  z-index: 11;
}

.splash_vote p {
  margin-bottom: 0;
}

.splash_vote .vote_thumb {
  position: absolute;
  left: 64px;
  top: -17px;
  width: 120px;
  height: 120px;
  border-radius: 100%;
  background: #c51453;
  border: 3px solid #503349;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.splash_vote .vote_thumb {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 3px;
}

.splash_vote .vote_thumb p {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 0;
}

.splash_vote .vote_thumb img {
  width: 25px;
}

.splash_banner > div:first-child .splash_vote {
  display: block;
}

.splash_banner > div:last-child .splash_vote {
  display: none;
}

@media screen and (max-width: 1100px) {
  .splash_banner {
    flex-direction: column;
  }

  .splash_banner > div:first-child,
  .splash_banner > div:last-child {
    width: 100%;
  }

  .splash_banner > div:first-child .splash_vote {
    display: none;
  }

  .splash_banner > div:last-child .splash_vote {
    display: block;
  }

  .line4,
  .line5,
  .line6,
  .line7 {
    display: none;
  }

  .homeFooterWrapper.pb {
    padding-bottom: 3rem;
  }

  .splash_vote {
    margin-top: 13rem;
  }

  .splash_vote .vote_thumb {
    left: 26px;
  }
}

@media screen and (max-width: 768px) {
  .splash_party {
    width: 100%;
  }
}

@media screen and (max-width: 688px) {
  .splash_trailer {
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .splash_trailer {
    flex-direction: column;
  }

  .splash_watch_ads .swa {
    padding: 14px;
  }

  .line1 {
    left: -130px;
  }

  .line2 {
    top: 150px;
  }
}

@media screen and (max-width: 525px) {
  .splash_trailer {
    flex-direction: column;
  }

  .splash_watch_ads {
    flex-direction: column;
    row-gap: 1.9rem;
    margin: 2rem 0;
  }

  .splash_watch_ads .swa p {
    bottom: -20px;
    left: -19px;
  }

  .swa_2 {
    flex-direction: row;
  }

  .splash_party {
    flex-direction: column;
  }

  .splash_party > div:first-child p {
    max-width: 100%;
  }

  .splash_party > div:last-child {
    bottom: -125px;
  }

  .splash_vote {
    font-size: 14px;
    padding-top: 20px;
    padding-left: 160px;
    padding-bottom: 20px;
  }
}
