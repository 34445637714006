.recruitmentWrapper {
  width: 100%;
  background: #f5f5f5;
  padding: 2%;
}

.recruitmntInfoPanels {
  background: white;
  border-radius: 8px;
  padding-top: 6%;
  padding-bottom: 6%;
  margin-left: 0.1%;
  margin-right: 0.1%;
}

.recruitmntDiv {
  background: white;
  border-radius: 8px;
}

.recruitmentProInfo {
  padding-top: 2%;
  padding-bottom: 2%;
  margin-left: 0.1%;
  margin-right: 0.1%;
  margin-top: 3%;
}

.recruitmentIcons {
  background: #c51453;
  color: white;
  border-radius: 100%;
  padding: 12%;
  transform: translate(0, 10%);
}

.recruitmentHead {
  padding-top: 10%;
}

.recgoProBtn {
  width: 80%;
  background: #c51453;
  color: white;
  border-radius: 8px;
  height: 40px;
  margin-top: 7%;
}

.recruitmentProHead {
  font-size: 150%;
  margin-top: 1%;
}

.recruitmentProSubHead {
  font-size: 120%;
}

.recruitmentFilter {
  /*background: red;*/
  padding-top: 0%;
  padding-bottom: 0%;
  margin-left: 0.1%;
  margin-right: 0.1%;
  margin-top: 3%;
}

.recruitmentJobs {
  padding-top: 0%;
  padding-bottom: 0%;
  margin-left: 0.1%;
  margin-right: 0.1%;
  /*margin-top: 3%;*/
  padding-bottom: 3%;
}

.filterCircles1 {
  background: purple;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  transform: translate(0, 80%);
}

.filterCircles2 {
  background: orange;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  transform: translate(0, 80%);
}

.recFilterCate {
  margin-right: 1%;
}

.recFilterIconM {
  font-size: 250%;
  color: #c51453;
  margin-top: 35%;
}

.recruitmentFilterHead {
  font-size: 150%;
  margin-top: 1%;
}

.recruitmentFilterSubHead {
  font-size: 120%;
}

.recruitmntInfoDiv {
  background: white;
  border-radius: 8px;
}

.rectSeeMoreBtn {
  width: 90%;
  background: #c51453;
  color: white;
  border-radius: 8px;
  height: 40px;
  /*margin-top: 9%;*/
}

.recruitmentJobs img {
  height: 220px;
  object-fit: cover;
  border-bottom: 1px solid #02020259;
}

@media screen and (max-width: 1024px) {
  .recruitmntInfoPanels {
    margin-bottom: 5%;
  }

  .recFilterIconM {
    font-size: 250%;
    margin-top: 15%;
  }
}


@media screen and (max-width: 991px) {
  .recruitmentProHead {
    font-size: 130%;
  }

  .recruitmentProSubHead {
    font-size: 100%;
  }

  .recruitmentFilterHead {
    font-size: 130%;
    margin-top: 1%;
  }

  .recruitmentFilterSubHead {
    font-size: 100%;
  }

  .recFilterIconM {
    font-size: 230%;
    /*margin-top: 15%*/
  }

  .recruitmentJobs img {
    height: 160px;
  }
}

@media screen and (max-width: 800px) {
  .recruitmentProHead {
    font-size: 110%;
  }

  .recruitmentProSubHead {
    font-size: 90%;
  }

  .recruitmentFilterHead {
    font-size: 110%;
    margin-top: 1%;
  }

  .recruitmentFilterSubHead {
    font-size: 90%;
  }

  .recFilterIconM {
    font-size: 200%;
    /*margin-top: 15%*/
  }
}

@media screen and (max-width: 700px) {
  .recruitmentProHead {
    font-size: 95%;
  }

  .recruitmentProSubHead {
    font-size: 85%;
  }

  .recruitmentFilterHead {
    font-size: 100%;
    margin-top: 1%;
  }

  .recruitmentFilterSubHead {
    font-size: 86%;
  }

  .recFilterIconM {
    font-size: 200%;
    /*margin-top: 15%*/
  }
}

@media screen and (max-width: 575px) {
  .recruitmentIcons {
    padding: 25%;
    font-size: 15px;
  }

  .recruitmentHead {
    font-size: 90%;
    padding-top: 30%;
  }

  .recruitmentProHead {
    font-size: 61%;
  }

  .recruitmentProSubHead {
    font-size: 58%;
  }

  .recgoProBtn {
    font-size: 75%;
    width: 100%;
    background: #c51453;
    color: white;
    border-radius: 8px;
    height: 28px;
    margin-top: 9%;
  }

  .recFilterIconM {
    font-size: 130%;
    margin-top: 25%;
  }

  .filterCircles1 {
    background: purple;
    height: 6px;
    width: 6px;
    border-radius: 100%;
    transform: translate(0, 50%);
  }

  .filterCircles2 {
    background: orange;
    height: 6px;
    width: 6px;
    border-radius: 100%;
    transform: translate(0, 50%);
  }

  .recruitmentFilterHead {
    font-size: 78%;
    margin-top: 1%;
  }

  .recruitmentFilterSubHead {
    font-size: 65%;
  }

  .recFilterIconM {
    font-size: 130%;
    /*margin-top: 15%*/
  }

  .recruitmentFilter {
    margin-top: 6%;
  }
}

@media screen and (max-width: 400px) {
}
