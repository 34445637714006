.home_header {
    padding: 10px 20px;
}

.home_header_cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding-bottom: 5px;
}

.homepage .home_logo {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.homepage .home_logo img {
    width: 80px;
}

.homepage .home_logo span {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.21em;
}

.home_header_cont .home_header_right {
    display: flex;
    align-items: center;
}

.home_header_cont .home_header_right a {
    color: #FFFFFF;
    transition: 0.3s all ease-in-out;
}

.home_header_cont .home_header_right a:nth-child(2) {
    margin: 0 30px;
}

.home_header_cont .home_header_right a:hover {
    color: rgba(255, 255, 255, 0.8);
}

.homepage .home_btn {
    /* background: linear-gradient(180deg, #C51453 0%, #FFB238 100%); */
    background: #533781;
    border: 0;
    color: #FFFFFF;
    border-radius: 100px;
    padding: 9px 20px;
    border: 1px solid #FFFFFF;
    font-weight: 500;
}

@media screen and (max-width:1400px) {
    .homepage .home_logo img {
        width: 60px;
        margin-bottom: 2px;
    }

    .homepage .home_logo span {
        font-size: 12px;
    }

    .homepage .home_btn {
        padding: 7px 20px;
    }
}

@media screen and (max-width:768px) {
    .home_header {
        padding: 10px;
    }

    .homepage .home_logo img {
        width: 44px;
    }

    .homepage .home_logo span {
        font-size: 10px;
    }

    .home_header_cont .home_header_right a:nth-child(2) {
        margin: 0 10px;
    }

    .home_header_cont .home_header_right a,
    .homepage .home_btn {
        font-size: 12px;
    }

    .homepage .home_btn {
        padding: 6px 20px;
    }
}