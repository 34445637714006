.dashboard__panel {
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  overflow: hidden;
  font-size: 14px !important;
}

.dashboard__panel .dashboard {
  padding: 0 1rem 1rem 1rem;
  height: calc(100% - 82px);
  width: 100%;
  overflow-y: scroll;
}

.dashboard__panel .dashboard .dashboard__cont {
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.dashboard .dashboard__cont > div {
  height: auto;
  background: #f9f9f9;
  border-radius: 8px;
  padding: 14px;
}

.dashboard__cont .dashboard__child1 {
  width: calc(100% - 260px);
}

.dashboard__cont .dashboard__child2 {
  width: 250px;
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  flex-wrap: wrap;
}

.dashboard p {
  margin-bottom: 0 !important;
}

.dashboard a {
  text-decoration: underline !important;
  color: #c51453 !important;
  font-size: 12px !important;
}

.dashboard a:hover {
  text-decoration: none !important;
  color: #c51452c4 !important;
}

.dashboard__child1_3 {
  display: flex;
  flex-wrap: wrap;
  column-gap: 14px;
  row-gap: 14px;
}

.dashboard__child1_3 > div:last-child {
  min-width: calc(100% - 374px);
  width: calc(100% - 374px);
  display: flex;
  flex-direction: column;
  row-gap: 14px;
}

.dashboard__analytics > div:first-child {
  width: 100%;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
}

.dashboard__analytics > div:last-child {
  width: 100%;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
}

.dashboard__analytics > div > div {
  cursor: pointer;
}

@media screen and (max-width: 1280px) {
  .dashboard__child1_3 {
    flex-direction: column;
  }

  .dashboard__child1_3 > div:last-child,
  .dashboard__child1_3 .dashboard__community__wrapper {
    min-width: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .dashboard__panel .dashboard .dashboard__cont {
    flex-direction: column;
    row-gap: 16px;
    justify-content: flex-start;
  }

  .dashboard .dashboard__cont > div,
  .dashboard__child1_3 > div:last-child {
    width: 100% !important;
  }
}

@media screen and (max-width: 768px) {
  .dashboard__panel .dashboard {
    height: calc(100% - 72px);
    padding: 0 12px 1rem 12px;
    padding-bottom: 0;
  }

  .dashboard .dashboard__cont > div {
    padding: 10px;
  }

  .dashboard__analytics > div:first-child {
    grid-template-columns: repeat(1, 1fr);
  }

  .dashboard__analytics > div:last-child {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .dashboard__analytics > div:last-child {
    grid-template-columns: repeat(1, 1fr);
  }
}
