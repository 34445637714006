.chat__cont {
    height: 100% !important;
}

.chat__cont>div {
    height: auto;
    background: #F9F9F9;
    border-radius: 8px;
    padding: 14px;
}

.chat__cont .chat__child1, .chat__cont .chat__child3 {
    width: 300px;
}

.chat__cont .chat__child2 {
    width: calc(100% - 620px)
}

.chat__cont .chat__child1 .chat__list__search img {
    width: 20px;
    margin-right: 6px;
}

.chat__cont .chat__child1 .chat__list__search input {
    width: calc(100% - 27px);
}

.chat__cont .chat__child1 .chat__list {
    height: calc(100% - 42px);
    overflow-y: auto;
}

.chat__cont .chat__child1 .chat__list .chat__list__card {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.chat__cont .chat__child1 .chat__list .chat__list__card img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 100%;
    margin-right: 8px;
}

.chat__cont .chat__time {
    font-size: 12px;
    color: gray;
}

.chat__cont .chat__child1 .chat__list .chat__list__card .chat__content span {
    font-size: 16px;
    font-weight: 600;
}

.chat__cont .chat__child1 .chat__list .chat__list__card .chat__content {
    overflow: hidden;
}

.chat__cont .chat__child1 .chat__list .chat__list__card .chat__content p {
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.chat__cont .chat__child1 .chat__list .chat__list__sep {
    border: 1px solid #f9f9f9;
    margin: 14px 0 !important;
    width: 100%;
}

.chat__cont .chat__child1 .chat__list .chat__count {
    border-radius: 100%;
    background-color: red;
    color: #FFFFFF;
    font-size: 12px;
    width: 16px;
    height: 16px;
    text-align: center;
    font-weight: 600;
    margin-top: 5px;
}

.chat__status__online {
    width: 12px;
    height: 12px;
    position: absolute;
    top: 2px;
    left: 1px;
    border: 2px solid #fff;
    border-radius: 100%;
}

.chat__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #f9f9f9;
}

.chat__header .icon__wrapper {
    font-size: 16px;
    cursor: pointer;
}

.chat__header .icon__wrapper path {
    fill: #c51453;
}

.chat__header .chat__header__img {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 10px;
}

.chat__header .chat__header__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.chat__header .chat__header__info>div {
    font-size: 16px;
    font-weight: 600;
}

.chat__header .chat__header__info p {
    font-size: 12px;
    color: gray;
    margin-bottom: 0;
}

.chat__footer {
    padding-top: 10px;
    border-top: 1px solid #f9f9f9;
}

.chat__footer form {
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 7px 10px;
}

.chat__footer input {
    width: calc(100% - 74px);
    padding-right: 5px;
}

.chat__footer path {
    fill: #c51453;
}

.chat__footer #chat__file {
    display: none;
}

.chat__footer svg {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.chat__footer label {
    cursor: pointer;
    margin: 0 10px;
}

.chat__footer button {
    background: transparent;
}

.chat__body {
    height: calc(100% - 56px - 48px);
    overflow-y: auto;
    background: #f9f9f9;
    border-radius: 8px;
    padding: 10px;
}

.chat__body__content {
    height: 100%;
}

.chat__content__child {
    display: flex;
    flex-direction: column;
    width: max-content;
    row-gap: 3px;
    margin-bottom: 7px;
}

.chat__content__child img {
    width: 150px;
    border-radius: 8px;
    display: block;
    box-shadow: 0px 1px 8px rgb(0 0 0 / 10%);
}

.chat__content__child p {
    font-size: 0.85rem;
    border-radius: 8px;
    background-color: #FFFFFF;
    width: fit-content;
    padding: 5px 10px;
    box-shadow: 0px 1px 8px rgb(0 0 0 / 10%);
    max-width: 390px;
    margin-bottom: 0;
}

.chat__content__child span {
    font-size: 11px;
    color: gray;
}

.chat__body__left {
    align-items: flex-start;
    text-align: -webkit-left;
}

.chat__body__right {
    text-align: -webkit-right;
    align-items: flex-end;
    margin-left: auto;
}

.chat__body__right p {
    background-color: #c51453;
    color: #FFFFFF;
    text-align: start;
}

.chat__back__icon {
    margin-right: 14px;
    display: none;
}

.chat__child3 .icon__wrapper {
    margin-left: auto;
}

.chat__child3 .icon__wrapper svg {
    font-size: 16px;
    cursor: pointer;
}

.chat__child3 .icon__wrapper path {
    fill: #c51453;
}

.chat__right__profile img {
    border-radius: 100%;
    width: 75px;
    height: 75px;
    object-fit: cover;
    margin-bottom: 6px;
}

.chat__right__profile .chat__username {
    font-size: 16px;
    font-weight: 600;
}

.chat__right__profile .chat__right__sep {
    border: 1px solid #f9f9f9;
    margin: 14px 0 !important;
    width: 100%;
}

.chat__right__profile .chat__media p:first-child {
    color: #c51453;
    font-weight: 600;
}

.chat__right__profile .chat__media .chat__media__images {
    margin-top: 5px;
    display: flex;
    column-gap: 6px;
    row-gap: 6px;
    flex-wrap: wrap;
}

.chat__right__profile .chat__media .chat__media__images img {
    width: 60px;
    height: 60px;
    border-radius: 4px;
    object-fit: cover;
    margin-bottom: 0;
}

.chat__settings>div {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
}

.chat__settings>div svg {
    margin-right: 6px;
}

.chat__settings>div path, .chat__settings>div p {
    fill: #c51453;
    color: #c51453;
}

@media screen and (min-width: 992px) {

    .chat__cont .chat__child3__display {
        display: none;
    }

    .chat__cont .chat__child2__display {
        width: calc(100% - 310px)
    }
}

@media screen and (max-width: 991px) {
    .chat__back__icon {
        display: block;
    }

    .chat__cont .chat__child1, .chat__cont .chat__child2, .chat__cont .chat__child3 {
        display: none;
    }

    .chat__cont .chat__child1__display, .chat__cont .chat__child2__display_, .chat__cont .chat__child3__display_ {
        display: block;
    }

    .chat__child2__display_ {
        height: 100% !important;
    }

    .chat__child2 {
        padding: 5px !important;
    }
}