.dashboard__child1_3 .dashboard__community__wrapper {
  width: 360px;
}

.community__create__wrapper {
  display: flex;
  align-items: center;
}

.community__create__wrapper .community__user__img {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  overflow: hidden;
  margin-right: 4px;
}

.community__create__wrapper .community__user__img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.community__create__wrapper .community__input {
  min-width: calc(100% - 44px);
  background: #f9f9f9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 5px;
}

.community__create__wrapper .community__input input[type="text"] {
  width: calc(100% - 44px);
  background: transparent;
}

.community__create__wrapper .community__input input[type="file"] {
  display: none;
}

.community__create__wrapper .community__input label {
  margin-left: 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.community__banner__wrapper {
  border-radius: 8px;
  overflow: hidden;
}

.community__banner__wrapper .community__banner {
  background: linear-gradient(180deg, #c51453 0%, #ffb238 100%);
  border-radius: 8px;
  padding: 12px;
}

.community__banner__wrapper .community__banner h3 {
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 0.75rem;
}

.community__banner__wrapper .community__banner .banner__img__wrapper {
  background: #ffffff;
  border: 1px solid #ebebeb;
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 7px;
}

.community__banner__wrapper .community__banner button {
  font-size: 12px;
  font-weight: 500;
  color: #c51453;
  background: #ffffff;
  border: 0;
  border-radius: 6px;
  padding: 1px 7px;
}

.community__banner__wrapper .community__banner p {
  color: #ffffff;
  font-size: 12px;
}

.community__banner__wrapper .react-multi-carousel-dot button {
  border: 0;
  width: 8px;
  height: 8px;
  background: rgba(255, 255, 255, 0.52);
}

.community__banner__wrapper .react-multi-carousel-dot--active button {
  background: #ffffff;
}

.dashboard__community__sep {
  margin: 1.25rem 0;
  border: 1px solid rgba(187, 187, 187, 0.17);
  width: 100%;
}

.dashboard__community__feed__wrapper .feed__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.dashboard__community__feed__wrapper .feed__card {
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  height: 210px;
  position: relative;
}

.dashboard__community__feed__wrapper .feed__card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dashboard__community__feed__wrapper .feed__card .feed__content {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.94);
  padding: 6px 9px;
}

.feed__card .feed__content p {
  font-size: 12px;
  white-space: pre-wrap;
}

.feed__card .feed__content .feed__title {
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 3px;
}

.feed__content__sep {
  /* border: 1px solid rgba(255, 255, 255, 0.95); */
  border: 1px solid #d3d3d3;
  width: 100%;
  margin: 7px 0;
}

.feed__action {
  display: flex;
  align-self: center;
  justify-content: flex-end;
}

.feed__action img {
  width: 17px !important;
  margin-left: 7px;
  cursor: pointer;
}

.dashboard__community__wrapper .feed__cont {
  height: 360px;
  overflow-y: scroll;
  position: relative;
}

.feed__cont .scroll__down {
  background: rgba(0, 0, 0, 0.68);
  border-radius: 8px;
  width: 36px;
  height: 36px;
  position: sticky;
  bottom: 6px;
  right: 6px;
  margin-left: auto;
  display: grid;
  place-items: center;
  color: rgba(197, 20, 83, 1);
  cursor: pointer;
}

.feed__cont .scroll__down .bi-chevron-down::before {
  font-size: 20px;
  font-weight: 900 !important;
}

@media screen and (max-width: 991px) {
  .dashboard__child1_3 .dashboard__community__wrapper {
    width: 100%;
  }

  .dashboard__community__wrapper .feed__cont {
    height: 100%;
  }

  .feed__cont .scroll__down {
    display: none;
  }
}
