.dashboard__header {
    width: 100%;
    padding: 1rem 1.5rem;
}

.dashboard__header .header__cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header__logo img {
    width: 200px;
}

.header__nav,
.header__nav .nav__item,
.header__cont .header__avatar {
    display: flex;
    align-items: center;
}

.header__nav,
.header__nav .nav__item:not(:last-child) {
    margin-right: 1rem;
}

/* .header__nav .nav__item>div {
    border: 1px solid #EBEBEB;
    border-radius: 8px;
    padding: 6px;
} */

.header__nav .nav__item.active>div {
    border: 1px solid #C51453;
}

.header__nav .nav__item span {
    font-size: 14px;
    color: #000000;
    margin-right: 7px;
}

.header__cont .header__avatar img {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    object-fit: cover;
}

.header__cont .header__avatar span {
    font-size: 14px;
    margin-left: 10px;
    font-weight: 500;
    color: black;
}

.header__hamburger img {
    cursor: pointer;
}

.header__logo__nav,
.header__hamburger {
    display: none;
}

@media screen and (max-width: 990px) {

    .header__nav,
    .header__logo__,
    .header__cont .header__avatar .username {
        display: none;
    }

    .header__logo__nav,
    .header__hamburger {
        display: block;
    }
}

@media screen and (max-width: 768px) {
    .dashboard__header {
        padding: 1rem;
    }

    .header__logo img {
        width: 150px;
    }

    .header__cont .header__avatar img {
        width: 40px;
        height: 40px;
    }
}