.mission__wrapper .mission__body {
    display: flex;
    align-items: center;
}

.mission__wrapper .mission__body .mission__title p {
    font-weight: 600;
    font-size: 14px;
    line-height: 9px;
}

.mission__wrapper .mission__body .mission__title span {
    font-size: 11px;
}

.mission__progress {
    display: flex;
    align-items: center;
}

.mission__progress>div {
    width: 100%;
}

.mission__progress span {
    font-size: 10px;
    margin-left: 4px;
}