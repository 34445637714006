.dashboard__analytics {
  display: flex;
  margin: 1rem 0;
  flex-wrap: wrap;
  gap: 16px;
}

.dashboard__analytics .custom__card {
  /* width: 210px; */
  width: 100%;
  min-height: 100%;
  max-height: 175px;
}

.dashboard__analytics .top__upload__cont {
  /* min-width: 390px;
  width: 390px; */
}

.dashboard__analytics .card__top__wrapper {
  display: flex;
  align-items: center;
}

.dashboard__analytics .top__upload__cont .card__top__wrapper > div {
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
  background: #c51453;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 6px;
}

.dashboard__analytics .card__top__wrapper p {
  font-size: 15px;
  color: #4f4f4f;
}

.dashboard__analytics .custom__card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard__analytics .custom__card select {
  border: 1px solid rgba(255, 255, 255, 0.24);
  border-radius: 4px;
  font-size: 10px;
  padding: 1px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  background-color: rgb(255, 255, 255, 0.71);
  color: #c51453;
}

.top__upload__cont .custom__card__body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top__upload__cont .custom__card__body .image__wrapper {
  width: 175px;
  border-radius: 5px;
  overflow: hidden;
}

.top__upload__cont .custom__card__body .image__wrapper img {
  width: 100%;
  object-fit: cover;
}

.top__upload__cont .custom__card__body table {
  min-width: 190px;
}

.dashboard__analytics table td {
  padding-bottom: 4px;
}

.wallet__cont {
  background: linear-gradient(180deg, #c51453 0%, #ffb238 100%);
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
}

.wallet__cont p {
  color: #ffffff !important;
}

.card__top__wrapper img {
  margin-right: 6px;
}

.wallet__cont select {
  background: rgba(255, 255, 255, 0.71);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-size: 15px;
  color: #c51453 !important;
}

.wallet__cont .level__text {
  text-align: center;
}

.wallet__cont .level__text p {
  font-size: 36px;
  line-height: 0.9;
}

.wallet__cont .level__text span {
  font-size: 12px;
  color: #ffffff;
}

.wallet__select {
  text-align: end;
}

.affiliate__cont .affiliate__table {
  display: flex;
  justify-content: center;
}

.affiliate__cont .affiliate__table table {
  min-width: 145px;
  color: #4f4f4f;
}

.affiliate__cont > p {
  font-size: 12px;
  color: #717171;
}

@media screen and (max-width: 1350px) {
  .dashboard__analytics {
    justify-content: flex-start;
    column-gap: 16px;
  }
}

@media screen and (max-width: 768px) {
  .dashboard__analytics .custom__card {
    max-height: 200px;
  }
}

/* .quick__upload__cont .custom__card {
  width: auto;
} */

@media screen and (max-width: 500px) {
  .quick__upload__cont .custom__card,
  .quick__upload__cont {
    width: 100%;
  }

  .dashboard__analytics .custom__card {
    width: 100%;
    max-height: fit-content;
  }

  .dashboard__analytics .custom__card.ad__views__cont {
    height: 155px;
  }

  .dashboard__analytics .top__upload__cont,
  .dashboard__analytics table {
    min-width: 100% !important;
    width: 100%;
  }

  .top__upload__cont .custom__card__body {
    flex-direction: column;
    row-gap: 7px;
    align-items: normal;
  }

  .top__upload__cont .custom__card__body .image__wrapper {
    width: 100%;
  }
}
