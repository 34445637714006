.dashboard__user__level .user__level__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.user__level__wrapper p {
    font-weight: 700;
}

.xp__wrapper {
    font-size: 12px;
    display: flex;
    align-items: center;
    margin: 5px 0;
}

.xp__wrapper img {
    margin-right: 4px;
}

.level__pro {
    font-size: 12px;
    border: 1px solid #EBEBEB;
    border-radius: 4px;
    padding: 1px 7px;
    margin-top: 6px;
    font-weight: 700;
}