.home_footer {
    background-color: #171717;
}

.home_footer .home_footer_top {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home_footer .home_footer_top h3 {
    margin-bottom: 2rem;
}

.home_footer .social_links {
    margin: 24px 0;
}

.home_footer .social_links span:not(:last-child) {
    margin-right: 24px;
}

.home_footer .social_links svg {
    width: 24px;
    height: 24px;
    color: #FFFFFF;
    cursor: pointer;
}

.footer_phone_email {
    display: flex;
    align-items: center;
}

.footer_phone_email div {
    width: 1px;
    height: 20px;
    background-color: #FFFFFF;
    margin: 0 24px;
}

.footer_phone_email p {
    font-weight: 500;
    margin-bottom: 0;
}

.home_footer .footer_inputs {
    margin: 24px 0;
}

.home_footer .footer_inputs input:first-child {
    margin-right: 24px;
}

.home_footer .footer_inputs input {
    border: 1px solid #FFF;
    border-radius: 100px;
    outline: none;
    color: white;
    background: transparent;
    padding: 8px 18px;
    width: 300px;
    font-style: italic;
}

.home_footer .footer_inputs input::placeholder {
    color: #FFFFFF80;
}

.home_footer_bottom {
    border-top: 1px solid #d6d5d0;
    padding: 16px 0;
    text-align: center;
}

@media screen and (max-width:768px) {
    .home_footer .social_links {
        margin: 16px 0;
    }

    .home_footer .social_links span:not(:last-child) {
        margin-right: 16px;
    }

    .home_footer .social_links svg {
        width: 16px;
        height: 16px;
    }

    .footer_phone_email {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .footer_phone_email div {
        height: 24px;
        margin: 10px 0;
    }

    .footer_phone_email p {
        font-weight: 400;
        font-size: 14px;
    }

    .home_footer .footer_inputs {
        margin: 16px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .home_footer .footer_inputs input:first-child {
        margin-right: 0;
        margin-bottom: 14px;
    }

    .home_footer .footer_inputs input {
        font-size: 14px;
        padding: 6px 18px;
        width: 250px;
    }

    .home_footer .footer_inputs input::placeholder {
        color: #FFFFFF80;
    }

    .home_footer_bottom {
        padding: 10px 0;
        font-size: 12px;
    }
}