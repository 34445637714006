.friends__wrapper .friends__users {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 7px;
}

.friends__img {
    width: 45px;
    height: 45px;
}

.friends__img img {
    border-radius: 6px;
    object-fit: cover;
    width: 100%;
}

.add__friend {
    background: #C51453;
    color: #FFFFFF;
    border-radius: 8px;
    cursor: pointer;
    padding: 4px 8px;
}
.friends__wrapper .follow__count{
    font-size: 12px;
    margin-bottom: 4px !important;
}