/* filter  */
.dashboard__filters {
    display: flex;
}

.dashboard__filters img {
    cursor: pointer;
}

.input__filter, .sort__filter {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding: 6px 10px;
}

.input__filter {
    width: calc(100% - 235px);
}

.input__filter input {
    padding: 0 10px;
    border: 0;
    font-size: 14px;
    color: #000000;
    width: calc(100% - 176px);
}

.input__filter, .filter_wrapper, .sort__filter {
    display: flex;
    align-items: center;
}

.sort__filter {
    justify-content: space-between;
}

.filter_wrapper {
    margin-right: 0 !important;
}

.filter_wrapper p {
    border: 1px solid #EBEBEB;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    margin-left: 10px;
}

.filter__spacer {
    margin: 0 6px;
}

@media screen and (max-width:991px) {
    .dashboard__filters {
        flex-direction: column;
    }

    .input__filter {
        width: 100%;
    }

    .filter__spacer {
        margin: 6px 0;
    }
}