.home_intro {
    background-color: #000;
}

.home_intro>.row {
    position: sticky;
    top: 0;
    left: 0;
    background-color: #000;
}

.home_intro>.row img {
    height: 100%;
    object-fit: cover;
}

.home_intro .intro_card {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    min-height: 90vh;
    height: 100%;
    background-color: #000;
}

.home_intro .intro_card .card_title {
    font-size: 70px;
    line-height: 1.05;
    font-weight: 600;
    margin-bottom: 1rem;
}

.home_intro .intro_card .card_title span {
    color: #533781;
}

@media screen and (max-width: 1200px) {
    .home_intro .intro_image {
        display: none;
    }

    .home_intro .intro_card {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }
}

@media screen and (max-width: 990px) {
    .home_intro .intro_card .card_title {
        margin-bottom: 7px;
        font-size: 26px;
    }

    .intro_card p {
        font-size: 13px;
    }
}