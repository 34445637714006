.home_dreambird {
    height: 650px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.home_dreambird .hero_title {
    max-width: 100% !important;
}

.home_dreambird img {
    width: 100%;
    margin-top: 48px;
}

@media screen and (max-width:768px) {
    .home_dreambird {
        height: 440px;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .home_dreambird img {
        width: 100%;
        margin-top: 24px;
    }

    .home_dreambird .hero_title {
        font-size: 33.5px !important;
    }
}