.homeFooterWrapper{
    width: 100%;
    background: #36132f;
    color: white;
    padding: 3%;
    overflow-x: hidden;
}
.staringImg{
    width: 60%;
}
.starringTable{
    width: 100%;
    border-collapse: separate;
    border-spacing: 0.5em;
}
.starringTable tr{
    width: 100%;
}
.staringImgTd{
    width: 20%;
}
.staringText1{
    width: 30%;
}
.staringText2{
    width: 50%;
}
.producerTabele{
    width: 100%;
}
.producerTabele tr{
    width: 100%;
    display: flex;
}
.producerText1{
    width: 50%;
}
.producerText2{
    width: 50%;
}
