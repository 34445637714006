.home_cards .col-12 {
    padding: 0 !important;
    margin: 0 !important;
}

.home_cards .home_card {
    border: 1px solid #FFFFFF;
    position: relative;
    height: 100%;
}

.home_card .card_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 0 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transition: 0.3s all ease-in-out;
}

.home_card .text_overlay {
    background-color: #000000;
    z-index: 11;
}

.home_card:hover .text_overlay {
    opacity: 0;
}

.home_card .image_overlay {
    background-color: #00000099;
    z-index: 10;
}

.home_card .image_overlay p {
    margin-bottom: 0;
    text-align: center;
}

.home_card .card_title {
    font-size: 80px;
    font-weight: 600;
    border-bottom: 10px solid #533781;
    margin-bottom: 0;
    line-height: 1.2;
}

.home_cards .home_card img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.image_overlay .card_title {
    display: none;
}

@media screen and (max-width:991px) {
    .home_card .card_overlay {
        padding: 0 1.1rem;
    }

    .home_card .image_overlay p {
        margin-top: 14px;
        font-size: 14px;
    }

    .home_card .card_title {
        font-size: 52px !important;
    }
}

@media screen and (max-width:768px) {
    .home_cards .col-12 {
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
    }

    .home_card .text_overlay {
        display: none;
    }

    .image_overlay .card_title {
        display: initial;
    }

    .home_cards .home_card img {
        height: 525px;
    }
}